import React, { useState } from "react";
import Img1 from "../../assets/resume/resume1.png";
import Img2 from "../../assets/images/resume2.png";
import Img3 from "../../assets/images/resume3.png";
import Img4 from "../../assets/images/resume4.png";
import Img5 from "../../assets/images/RESUME 5.png";
import Img6 from "../../assets/resume/resume2.png";
import Img7 from "../../assets/resume/resume3.png";
import Img8 from "../../assets/resume/resume4.png";
import Img9 from "../../assets/resume/resume5.png";
import Img10 from "../../assets/resume/resume6.png";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { selectTemplate } from "../../state/reducers/templateSlice";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FaX } from "react-icons/fa6";
import { setChooseTemplate } from "../../state/reducers/choosetemplateSlice";
import { toast, ToastContainer } from "react-toastify";

const ChooseTemplate = () => {
  const templateImages = [
    Img1,
    Img6,
    Img7,
    Img8,
    Img9,
    Img10,
    Img2,
    Img3,
    Img4,
    Img5,
  ];

  const citiesName = [
    "Lisbon",
    "Rome",
    "Madrid",
    "Vanice",
    "Athens",
    "Porto",
    "Berlin",
    "Hamburg",
    "Munich",
    "Milan",
  ];
  const [hoveredTemplate, setHoveredTemplate] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const Router = useNavigate();

  const animDiv = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.5,
        damping: 1,
      },
    },
  };
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };
  const handleMouseEnter = (index) => {
    setHoveredTemplate(index);
  };

  const handleMouseLeave = () => {
    setHoveredTemplate(null);
  };
  const handleSelectTemplate = (templateId) => {
    if (selectedTemplate === templateId) {
      // Deselect if the same template is clicked again
      setSelectedTemplate(null);
    } else {
      // Select the new template
      setSelectedTemplate(templateId);
    }
  };
  const dispatch = useDispatch();

  const onSubmit = async (values) => {
    const token = localStorage.getItem("authToken");
    const fileUrl = "/static/media/resume1.53925282b11e926d0907.png";

    try {
      const response = await fetch(fileUrl);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const blob = await response.blob();
      console.log(blob instanceof Blob); // Should be true

      const formData = new FormData();
      formData.append("image", blob, "screenshot.png");

      const axiosResponse = await axios({
        method: "post",
        url: "/api/v1/template",
        headers: {
          Authorization: "Bearer " + token,
        },
        data: formData,
      });

      const template = axiosResponse.data.templateid;
      Router(`/dashboard/create-resume/${template}`);
    } catch (err) {
      showToast(err);
    }
  };
  return (
    <div className=" py-5  opacity-[0.9] w-full sm:h-full flex flex-col justify-center items-center">
      <div className="w-full px-[25px] mb-5">
        <button
          onClick={() => {
            dispatch(setChooseTemplate(false));
          }}
          className="px-4 py-2 bg-clearanceDarkBlue text-white"
        >
          <FaX />
        </button>
      </div>
      <h1 className="text-black font-semibold text-[25px]">Choose Template</h1>
      <div className="flex gap-x-5 w-[90%] mt-7">
        <motion.div
          variants={animDiv}
          initial="hidden"
          whileInView="visible"
          className="bg-white shadow-xl rounded-xl w-full  px-5 py-2"
        >
          <div className="flex flex-wrap gap-x-7 gap-y-12 py-5 justify-center mt-5">
            {templateImages.map((img, index) => (
              <div
                key={index}
                className="relative w-[220px] transition-all h-[250px] border border-black bg-white "
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  src={img}
                  alt={`Resume Template ${index + 1}`}
                  className="w-full h-[240px] object-fill "
                />
                <div className="w-full flex mt-4 justify-center">
                  <h2>{citiesName[index]}</h2>
                </div>

                {hoveredTemplate === index ? (
                  <div className="absolute top-0 transition-all w-full h-full flex justify-center bg-black opacity-[0.7] items-center">
                    {selectedTemplate === index ? (
                      <button
                        onClick={() => handleSelectTemplate(index)}
                        className="px-4 py-2 text-green-500 border-2 border-green-500"
                      >
                        Selected
                      </button>
                    ) : (
                      <button
                        onClick={() => {
                          handleSelectTemplate(index);
                          dispatch(selectTemplate(index + 1));
                        }}
                        className="px-4 py-2 text-white border-2 border-white"
                      >
                        Select
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            ))}
          </div>
          <div className="flex justify-end my-7">
            <button
              className="px-4 py-2 bg-clearanceDarkBlue text-white rounded-md"
              onClick={onSubmit}
            >
              Next
            </button>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default ChooseTemplate;
