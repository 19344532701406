import React, { useRef, useState } from "react";
import clearanceLogo from "../../../assets/logos/black-logo.svg";
import clearanceLogoRight from "../../../assets/logos/logo.svg";
import circleAssetLeft from "../../../assets/images/circleAssetLeft.svg";
import SignupForm from "../../../forms/SignupForm";
import logo from "../../../assets/images/logo.png";
import { toast } from "react-toastify";
import axios from "axios";
import { useFormik } from "formik";
import SignupFormSchema from "../../../forms/SignupForm/validation";
import { Link, useNavigate } from "react-router-dom";
function SignupPage() {
  const [isLoading, setIsLoading] = useState(false);
  const [serverError, setServerError] = useState(""); // For server error messages
  const [serverRes, setServerRes] = useState(""); // For server error messages

  const Router = useNavigate();
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const onSubmit = async (values) => {
    setIsLoading(true);

    try {
      const response = await axios.post("/api/v1/register", {
        name: values.fullName,
        email: values.email,
        password: values.password,
      });
      if (response && response.data.message) {
        setServerRes(response.data.message);
      }
      // Navigate or set user state on successful signup, depending on your flow
      // Or wherever you want to redirect to
    } catch (error) {
      // Display the error message from the server
      if (error.response && error.response.data.message) {
        setServerError(error.response.data.message);
      } else {
        setServerError("An error occurred. Please try again later.");
      }
      showToast(error);

      setIsLoading(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      fullName: "",
      email: "",
      password: "",
      confirmpassword: "",
    },
    validationSchema: SignupFormSchema,
    onSubmit,
  });

  return (
    <div className="w-full h-full">
      <div className="w-full flex flex-col justify-center xl:flex-row bg-[#e2e8f0] h-full">
        <div className="hidden w-full xl:w-[60%] relative xl:flex justify-center items-center">
          <div className="absolute w-full h-full left-0 top-0 bg-resumeImg6 bg-no-repeat bg-cover bg-top"></div>
          <img
            src={logo}
            alt="instant ai resume builder"
            className="relative"
          />
        </div>
        <div className="flex justify-center items-center xl:hidden">
          <img
            src={logo}
            alt="instant ai resume builder"
            className="relative w-[250px] sm:w-[300px]"
          />
        </div>
        <div className="w-full py-[30px] xl:w-[40%]  bg-slate-200 flex flex-col justify-center items-center">
          <form
            onSubmit={formik.handleSubmit}
            className="border border-[#5654d5] p-[30px] w-[90%] sm:w-[60%] xl:w-[80%] bg-white rounded-[30px] flex flex-col gap-[10px]"
          >
            {/* full name input */}
            <div className="flex flex-col gap-[7px]">
              <label htmlFor="email">Full Name</label>
              <input
                type="text"
                name="fullName"
                id="fullName"
                value={formik.values.fullName}
                onChange={formik.handleChange}
                className="px-4 py-2 w-full rounded-[50px] border border-black"
              />
              <p className="text-left mt-3 text-xs">
                {formik.errors.fullName ? formik.errors.fullName : ""}
              </p>
            </div>
            {/* Email input */}
            <div className="flex flex-col gap-[7px]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                className="px-4 py-2 w-full rounded-[50px] border border-black"
              />
              <p className="text-left mt-3 text-xs">
                {formik.errors.email ? formik.errors.email : ""}
              </p>
            </div>
            {/* password input */}
            <div className="flex flex-col gap-[7px]">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                className="px-4 py-2 w-full rounded-[50px] border border-black"
              />
              <p className="text-left mt-3 text-xs">
                {formik.errors.password ? formik.errors.password : ""}
              </p>
            </div>
            {/* confirm password input */}
            <div className="flex flex-col gap-[7px]">
              <label htmlFor="confirmpassword">Confirm Password</label>
              <input
                type="password"
                name="confirmpassword"
                id="confirmpassword"
                value={formik.values.confirmpassword}
                onChange={formik.handleChange}
                className="px-4 py-2 w-full rounded-[50px] border border-black"
              />
              <p className="text-left mt-3 text-xs">
                {formik.errors.confirmpassword ? (
                  <p className="error-text">formik.errors.confirmpassword</p>
                ) : (
                  ""
                )}
              </p>
            </div>
            <div className="flex flex-col gap-[7px]">
              <button
                type="submit"
                disabled={isLoading}
                className="px-[30px] py-[12px] bg-[#e2e8f0] text-black font-semibold rounded-[50px] hover:bg-clearanceDarkBlue hover:text-white"
              >
                {isLoading ? "Signing Up..." : "Sign Up"}
              </button>
              {formik.errors.loginError && (
                <p className="mt-3 text-red-500">{formik.loginError}</p>
              )}
            </div>
            <p className="text-center my-2">
              Already have an Account?{" "}
              <Link
                to="/auth/login"
                className="text-clearanceDarkBlue font-semibold"
              >
                Login
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignupPage;
