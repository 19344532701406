import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import LoginFormSchema from "./validation";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { fetchUser } from "../../state/actions/authActions";
import { MdLock, MdLockOutline, MdMailOutline } from "react-icons/md";
import { toast } from "react-toastify";

function LoginForm() {
  const loadUserStatus = useSelector((state) => state.loadUser.status);
  useEffect(() => {
    console.log("hello i am logging in " + loadUserStatus);
  }, [loadUserStatus]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const Router = useNavigate();
  const [loginError, setLoginError] = useState(null);

  // Remember me - state
  const [rememberMe, setRememberMe] = useState();
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const onSubmit = async (values) => {
    setLoginError(null);

    try {
      const res = await axios.post("/api/v1/login", {
        email: values.email,
        password: values.password,
      });

      const token = res.data.token;

      localStorage.setItem("authToken", token);
      dispatch(fetchUser());

      if (res.status === 200) {
        navigate("/dashboard");
      }
    } catch (err) {
      showToast(err);

      // Assuming err.response.data contains the error message from your server
      if (err.response && err.response.data && err.response.data.message) {
        setLoginError(err.response.data.message);
      } else {
        setLoginError("An error occurred during login.");
      }
    }
  };

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginFormSchema,
    onSubmit: onSubmit,
  });

  return (
    <>
      <form
        className="flex flex-col mt-14 items-center"
        onSubmit={handleSubmit}
      >
        <div className="w-full relative border-[2px] my-4 bg-transparent border-clearanceDarkBlue flex items-center rounded-full px-5 gap-x-3">
          <label htmlFor="email" className="text-sm inline-block">
            <MdMailOutline size={20} className="text-clearanceDarkBlue" />
          </label>
          <input
            className="w-full h-14 rounded-full bg-cosretBlue- px-4 text-black text-sm focus:outline-none bg-transparent"
            id="email"
            type="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            placeholder="Email Address"
          />

          <p className="text-left mt-3 text-xs">
            {errors.email ? errors.email : ""}
          </p>
        </div>
        <div className="w-full relative border-[2px] my-4 bg-transparent border-clearanceDarkBlue flex items-center rounded-full px-5 gap-x-3">
          <label htmlFor="password" className="text-sm inline-block">
            <MdLockOutline size={20} className="text-clearanceDarkBlue" />
          </label>
          <input
            className="w-full h-14 rounded-full bg-cosretBlue- px-4 text-black text-sm focus:outline-none bg-transparent"
            id="password"
            type="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            placeholder="Password"
          />

          <p className="text-left mt-3 text-xs">
            {errors.password ? errors.password : ""}
          </p>
        </div>

        {/* Action buttons */}
        <div className="w-full mt-4">
          <button
            type="submit"
            className="h-14 px-7 py-2 w-full rounded-full bg-clearanceDarkBlue text-white"
          >
            Login
          </button>
          <div className="w-full mb-4 flex justify-end">
            <Link to="/auth/forgot-password">
              <button className="font-semibold">Forgot Password ?</button>
            </Link>
          </div>
          {/* Don't have an account? */}
          {loginError && <p className="mt-3 text-red-500">{loginError}</p>}

          <p className="text-center mt-8">
            Don't have an account?{" "}
            <Link
              to="/auth/signup"
              className="text-clearanceDarkBlue font-semibold"
            >
              Sign up
            </Link>
          </p>
        </div>
      </form>
    </>
  );
}

export default LoginForm;
