import { Link, Navigate, useNavigate } from "react-router-dom";

import { FiSettings } from "react-icons/fi";
import { TbLogout } from "react-icons/tb";
import { AiOutlineCreditCard, AiOutlineFile } from "react-icons/ai";
import { FiFileText } from "react-icons/fi";
import { LiaIdCardSolid } from "react-icons/lia";
import clearanceLogo from "../../../../assets/logos/logo.svg";
import { useState } from "react";
import axios from "axios";
import DashboardHome from "../DashboardHome";
import { setChooseTemplate } from "../../../../state/reducers/choosetemplateSlice";
import { useDispatch } from "react-redux";
import { RiCustomerService2Fill } from "react-icons/ri";
// import  resume1 from "../../../../assets/images/"
export default function DashboardNav({ isNavHidden, user, page }) {
  const navigate = useNavigate();
  // const onSubmit = async (values) => {
  //   const token = localStorage.getItem("authToken");
  //   const fileUrl = "/static/media/resume1.53925282b11e926d0907.png";

  //   try {
  //     const response = await fetch(fileUrl);
  //     if (!response.ok) {
  //       throw new Error(`HTTP error! Status: ${response.status}`);
  //     }
  //     const blob = await response.blob();
  //     console.log(blob instanceof Blob); // Should be true

  //     const formData = new FormData();
  //     formData.append("image", blob, "screenshot.png");

  //     const axiosResponse = await axios({
  //       method: "post",
  //       url: "/api/v1/template",
  //       headers: {
  //         Authorization: "Bearer " + token,
  //       },
  //       data: formData,
  //     });

  //     console.log(axiosResponse);
  //     const template = axiosResponse.data.templateid;
  //     navigate(`/dashboard/create-resume/${template}`);
  //   } catch (err) {
  //     console.error(err.message);
  //   }
  // };

  const router = useNavigate();

  const [isSalesOpen, setIsSalesOpen] = useState(false);
  const [isUploadCenterOpen, setIsUploadCenterOpen] = useState(false);

  const handleLogout = () => {
    localStorage.removeItem("authToken");
    // localStorage.removeItem("authToken");
    navigate("/");
    localStorage.setItem("");

    // window.location.href = "http://localhost:3000/";
  };
  const dispatch = useDispatch();

  const handleShowTemplate = () => {
    console.log("hello");
    dispatch(setChooseTemplate(true));
  };
  return (
    <nav
      className={`bg-clearanceDarkBlue xl:hidden text-white  md:w-[40%] lg:w-1/5 z-10 fixed w-[60%] min-h-screen lg:block text-accountableBrightGreen pl-2 pt-7 top-0 left-0 ${
        isNavHidden ? "hidden" : "block"
      }`}
    >
      <img
        src={clearanceLogo}
        alt="Accountable"
        className="w-[150px] mx-auto"
      />
      <ul className="-mt-0">
        {/* CATEGORY */}
        <li className="my-11">
          {/* ITEMS */}
          <ul className="mt-3">
            <li className={`my-8 ml-7 py-2 pl-3 rounded-l-lg pr-7`}>
              {/* <Link
                to={`/dashboard/create-resume/`}
                className="flex items-center justify-center rounded-lg gap-2 w-full h-10 bg-clearancePink text-clearanceDarkBlue font-semibold"> */}
              <button
                onClick={handleShowTemplate}
                className="flex items-center justify-center rounded-lg gap-2 w-full h-10 bg-clearancePink text-clearanceDarkBlue font-semibold"
              >
                <span className=" text-sm">Create Resume</span>
              </button>
              {/* </Link> */}
            </li>
            <li
              className={`my-8 ml-7 py-2 pl-3 rounded-l-lg ${
                page == "my-resumes" && "text-clearancePink font-semibold"
              }`}
            >
              <Link to="/dashboard" className="flex items-center gap-2">
                <AiOutlineFile size={16} className="" />
                <span className=" text-sm">My Resumes</span>
              </Link>
            </li>
            <li
              className={`my-8 ml-7 py-2 pl-3 rounded-l-lg ${
                page == "resume-formatting" &&
                "text-clearancePink font-semibold"
              }`}
            >
              <Link
                to="/dashboard/resume-formatting"
                className="flex items-center gap-2"
              >
                <FiFileText size={16} className="" />
                <span className="text-sm">Templates</span>
              </Link>
            </li>
            <li
              className={`my-8 ml-7 py-2 pl-3 rounded-l-lg ${
                page == "payment" && "text-clearancePink font-semibold"
              }`}
            >
              <Link to="/dashboard/payment" className="flex items-center gap-2">
                <AiOutlineCreditCard size={16} className="" />
                <span className="text-sm">Payment</span>
              </Link>
            </li>
            <li
              className={`my-8 ml-7 py-2 pl-3 rounded-l-lg ${
                page == "my-profile" && "text-clearancePink font-semibold"
              }`}
            >
              <Link
                to="/dashboard/my-profile"
                className="flex items-center gap-2"
              >
                <LiaIdCardSolid size={16} className="" />
                <span className="text-sm">My Profie</span>
              </Link>
            </li>
            <li
              className={`my-8 ml-7 py-2 pl-3 rounded-l-lg ${
                page == "my-profile" && "text-clearancePink font-semibold"
              }`}
            >
              <Link to="/contact-support" className="flex items-center gap-2">
                <RiCustomerService2Fill size={16} className="" />
                <span className="text-sm">Customer Support</span>
              </Link>
            </li>
          </ul>
        </li>
      </ul>

      <div className="absolute bottom-0">
        <ul>
          <li className={`my-8 ml-7 py-2 pl-3 rounded-l-lg text-white`}>
            <a
              className="block px-4 py-2 mt-2 text-sm md:mt-0 focus:text-gray-900 focus:bg-gray-200 focus:outline-none focus:shadow-outline"
              href="/"
              onClick={handleLogout}
            >
              <TbLogout size={16} className="" />
              Logout
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
