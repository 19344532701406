import React, { useEffect, useRef, useState } from "react";
import clearanceLogo from "../../../assets/logos/black-logo.svg";
import clearanceLogoRight from "../../../assets/logos/logo.svg";
import LoginForm from "../../../forms/LoginForm";
import circleAsset from "../../../assets/images/circleAsset.svg";
import logo from "../../../assets/images/logo.png";
import login from "../../../assets/images/login.avif";
import { useFormik } from "formik";
import LoginFormSchema from "../../../forms/LoginForm/validation";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import fetchUser from "../../../state/actions/authActions";

function LoginPage() {
  const loadUserStatus = useSelector((state) => state.loadUser.status);
  useEffect(() => {
    console.log("hello i am logging in " + loadUserStatus);
  }, [loadUserStatus]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const Router = useNavigate();
  const [loginError, setLoginError] = useState(null);

  // Remember me - state
  const [rememberMe, setRememberMe] = useState();
  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const onSubmit = async (values) => {
    setLoginError(null);

    try {
      const res = await axios.post("/api/v1/login", {
        email: values.email,
        password: values.password,
      });

      const token = res.data.token;

      localStorage.setItem("authToken", token);
      dispatch(fetchUser());

      if (res.status === 200) {
        navigate("/dashboard");
      }
    } catch (err) {
      showToast(err);

      // Assuming err.response.data contains the error message from your server
      if (err.response && err.response.data && err.response.data.message) {
        setLoginError(err.response.data.message);
      } else {
        setLoginError("An error occurred during login.");
      }
    }
  };

  const { values, errors, handleChange, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginFormSchema,
    onSubmit: onSubmit,
  });

  return (
    <div className="w-full h-full">
      <div className="w-full flex flex-col justify-center xl:flex-row bg-[#e2e8f0] h-full">
        <div className="hidden w-full xl:w-[60%] relative xl:flex justify-center items-center">
          <div className="absolute w-full h-full left-0 top-0 bg-resumeImg6 bg-no-repeat bg-cover bg-top"></div>
          <img
            src={logo}
            alt="instant ai resume builder"
            className="relative"
          />
        </div>
        <div className="flex justify-center xl:hidden">
          <img
            src={logo}
            alt="instant ai resume builder"
            className="relative w-[300px]"
          />
        </div>
        <div className="w-full py-[30px] xl:w-[40%]  bg-slate-200 flex flex-col justify-center items-center">
          <form
            onSubmit={handleSubmit}
            className="border border-[#5654d5] p-[30px] w-[90%] sm:w-[60%] xl:w-[80%] bg-white rounded-[30px] flex flex-col gap-[20px]"
          >
            <div className="flex flex-col gap-[7px]">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                className="px-4 py-2 w-full rounded-[50px] border border-black"
              />
              <p className="text-left mt-3 text-xs">
                {errors.email ? errors.email : ""}
              </p>
            </div>
            <div className="flex flex-col gap-[7px]">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                name="password"
                id="password"
                value={values.password}
                onChange={handleChange}
                className="px-4 py-2 w-full rounded-[50px] border border-black"
              />
              <p className="text-left mt-3 text-xs">
                {errors.password ? errors.password : ""}
              </p>

              <Link
                to="/auth/forgot-password"
                className="w-full flex justify-end"
              >
                <p className="font-semibold">Forgot Password ?</p>
              </Link>
            </div>
            <div className="flex flex-col gap-[7px]">
              <button
                type="submit"
                className="px-[30px] py-[12px] bg-[#e2e8f0] text-black font-semibold rounded-[50px] hover:bg-clearanceDarkBlue hover:text-white"
              >
                Login
              </button>
              {loginError && <p className="mt-3 text-red-500">{loginError}</p>}
            </div>
            <p className="text-center my-2">
              Don't have an account?{" "}
              <Link
                to="/auth/signup"
                className="text-clearanceDarkBlue font-semibold"
              >
                Sign up
              </Link>
            </p>
          </form>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
