import React from "react";

class Timer extends React.Component {
  state = {
    minutes: 0,
    seconds: 0,
    hours: 0,
    days: 0,
  };

  componentDidMount() {
    let date = new Date("February 21, 2024 01:15:00");
    // prettier-ignore
    date.setDate(20);
    let countDownDate = new Date(date).getTime();
    setInterval(() => {
      let now = new Date().getTime();
      let distance = countDownDate - now;
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
      this.setState({
        minutes: minutes,
        days: days,
        hours: hours,
        seconds: seconds,
      });
    }, 1000);
  }

  render() {
    const handleClick = () => {
      window.location.replace("https://wa.me/919887380087");
    };
    const { minutes, seconds, hours, days } = this.state;
    return (
      <div className="flex justify-center gap-2 bg-black">
        <h1 className="text-[40px] text-white">Offer Time Remaining</h1>

        <h1 className="text-[40px] w-[200px] text-white">
          {days < 10 ? `0${days}` : days}:{hours < 10 ? `0${hours}` : hours}:
          {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
        </h1>
        <button
          onClick={handleClick}
          class="ml-5 relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
          <span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            BOOK NOW
          </span>
        </button>
      </div>
    );
  }
}

export default Timer;
