// import html2canvas from "html2canvas";

// const exportAsImage = async (el) => {
//   try {
//     const canvas = await html2canvas(el);
//     const image = canvas.toDataURL("image/png", 1.0);
//     return image;
//   } catch (error) {
//     console.error("Error in exportAsImage:", error);
//     throw error; // If you want to propagate the error
//   }
// };

// export default exportAsImage;
