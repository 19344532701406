import React from "react";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { UserContext } from "..";
import { BiPlus } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { MdDelete, MdSearch, MdSettings } from "react-icons/md";
import { GiSettingsKnobs } from "react-icons/gi";
import { CiMenuKebab } from "react-icons/ci";
import { toast, ToastContainer } from "react-toastify";
import { AiFillCheckCircle } from "react-icons/ai";
import { AiFillCloseCircle } from "react-icons/ai";
// Images
import resumeSample from "../../../../assets/images/resume.png";
import mastercardLogo from "../../../../assets/images/mastercardLogo.svg";
import visaLogo from "../../../../assets/images/visaLogo.svg";
import americanExpressLogo from "../../../../assets/images/americanExpressLogo.svg";
import paypalLogo from "../../../../assets/images/paypalLogo.svg";
import { motion } from "framer-motion";
export default function Payment() {
  const { user, projects } = useContext(UserContext);
  const [isButtonVisibleFree, setIsButtonVisibleFree] = useState(false);
  const [isButtonVisibleYearly, setIsButtonVisibleYearly] = useState(false);
  const [isButtonVisibleMonth, setIsButtonVisibleMonth] = useState(false);

  const [isPaymentPlans, setIsPaymentPlans] = useState(true);
  const [isBillingInformations, setIsBillingInformations] = useState(false);
  const token = localStorage.getItem("authToken");
  const navigate = useNavigate();

  const showToast = (error) => {
    toast.error(`${error}`, {
      data: {
        title: "Error",
        text: error,
      },
    });
  };

  const showToasRes = (res) => {
    toast(`${res}`, {
      data: {
        title: "Error",
        text: res,
      },
    });
  };

  const fetchuser = async () => {
    const token = localStorage.getItem("authToken");
    try {
      await axios({
        method: "get",
        url: `/api/v1/getUser`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          const plan = res.data.userData.plan;
          if (plan === "yearly") {
            setIsButtonVisibleYearly(true);
          } else if (plan === "monthly") {
            setIsButtonVisibleMonth(true);
          } else if (plan === "basic") {
            setIsButtonVisibleFree(true);
          }
        })
        .catch((err) => {
          showToast(err);
        });
    } catch (error) {
      showToast(error);
    }
  };
  useEffect(() => {
    fetchuser();
  }, []);

  const handleMonthlyCheckout = () => {
    axios({
      method: "post",
      url: `/api/v1/create-checkout-session-monthly`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        window.location.href = res.data.url;
      })
      .catch((err) => {
        showToast(err);
      });
  };
  const handleYearlyCheckout = () => {
    try {
      axios({
        method: "post",
        url: `/api/v1/create-checkout-session-yearly`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          window.location.href = res.data.url;
        })
        .catch((err) => {
          showToast(err);
        });
    } catch (error) {
      showToast(error);
    }
  };
  const animDiv = {
    hidden: {
      opacity: 0,
      y: 150,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.5,
        damping: 1,
      },
    },
  };

  const handleUpgradePlan = () => {
    axios({
      method: "post",
      url: `/api/v1/upgrade-subscription`,
      headers: {
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => {
        showToasRes(res.data.message);
        console.log(res.data.message);
        navigate("/dashboard");
      })
      .catch((err) => {
        showToast(err);
      });
  };

  const handleDowngradePlan = () => {
    try {
      axios({
        method: "post",
        url: `/api/v1/downgrade-subscription`,
        headers: {
          Authorization: "Bearer " + token,
        },
      })
        .then((res) => {
          console.log(res.data);
          showToasRes(res.data);
          navigate("/dashboard");
        })
        .catch((err) => {
          showToast(err);
        });
    } catch (error) {
      showToast(error);
    }
  };
  const [modalNo, setModalNo] = useState(false);
  const showModalNo = () => {
    setModalNo((prev) => !prev);
  };
  const [modalYes, setModalYes] = useState(false);
  const showModalYes = () => {
    setModalYes((prev) => !prev);
  };
  const modalAnim = {
    hidden: {
      opacity: 0,
      y: "100vh",
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        mass: 0.7,
        damping: 0.6,
        duration: 0.4,
      },
    },
  };
  return (
    <>
      <ToastContainer />
      {/* LATEST ACTIVITY */}
      <section className="mt-0 pb-12 h-full relative text-clearanceDarkBlue">
        <h1 className="text-[28px] text-center lg:text-[35px] font-semibold">
          Choose the Perfect Plan
        </h1>
        <div className="w-full p-[30px] sm:p-[50px] my-7 bg-clearancePink flex flex-col xl:flex-row justify-between gap-[30px] items-center">
          {/* free pricing box */}
          <div className="border relative border-black w-full sm:w-[80%] xl:w-[30%] h-[800px] sm:h-[650px] xl:h-[800px] bg-white rounded-md p-[20px]">
            <div className="flex flex-col pb-[20px] gap-[30px] items-center">
              <div className="flex flex-col gap-1 w-full items-center text-center">
                <h2 className="text-[28px] text-clearanceDarkBlue font-semibold">
                  Free
                </h2>
                <h3 className="text-[22px]">
                  Discover Instant AI Resume Builder
                </h3>
              </div>
              <div className="flex flex-col items-center gap-2 w-full">
                <h2 className="text-[40px] font-semibold">
                  ₹0<span className="text-[20px]">/month</span>
                </h2>
                {isButtonVisibleFree ? (
                  <button className="px-[30px] my-2 py-[12px] text-black border-2 hover:border-clearancePink bg-clearanceLightBlue border-clearanceLightBlue hover:bg-clearancePink font-semibold rounded-md">
                    Your Plan
                  </button>
                ) : (
                  <button className="px-[30px] my-2 py-[12px] text-black border-2 border-clearancePink hover:bg-clearancePink font-semibold rounded-md">
                    Sign Up
                  </button>
                )}
              </div>
            </div>
            <div className="border-t-2 py-[20px] border-black flex flex-col gap-2">
              <h3 className=" text-[17px] font-normal">Everything in Free:</h3>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Create optimized resumes
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Copy and paste content from site
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Unlimited resume edits
              </p>
            </div>
            <div className="absolute bottom-[20px] left-[20px] w-full">
              <h2 className="text-[22px] font-semibold">See all Features</h2>
            </div>
          </div>

          {/* one time pricing box */}

          <div className="border relative border-black w-full sm:w-[80%] xl:w-[30%] h-[800px] sm:h-[650px] xl:h-[800px] bg-white rounded-md p-[20px]">
            <div className="flex flex-col pb-[20px] gap-[30px] items-center">
              <div className="flex flex-col gap-1 w-full items-center text-center">
                <h2 className="text-[28px] text-clearanceDarkBlue font-semibold">
                  One Time Purchase
                </h2>
                <h3 className="text-[22px]">
                  Discover Instant AI Resume Builder
                </h3>
              </div>
              <div className="flex flex-col items-center gap-2 w-full">
                <h2 className="text-[40px] font-semibold">
                  ₹49
                  <span className="text-[17px] font-normal">
                    /One Time Purchase
                  </span>
                </h2>
                {isButtonVisibleYearly ? (
                  <button className="px-[30px] my-2 py-[12px] text-black border-2 hover:border-clearancePink bg-clearanceLightBlue border-clearanceLightBlue hover:bg-clearancePink font-semibold rounded-md">
                    Your Plan
                  </button>
                ) : (
                  <button
                    onClick={handleYearlyCheckout}
                    className="px-[30px] my-2 py-[12px] text-black border-2 border-clearancePink hover:bg-clearancePink font-semibold rounded-md"
                  >
                    Sign Up
                  </button>
                )}
              </div>
            </div>
            <div className="border-t-2 py-[20px] border-black flex flex-col gap-2">
              <h3 className="font-semibold text-[20px]">Everything in Free:</h3>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Create optimized resumes
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Copy and paste content from site
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Unlimited resume edits
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Save resume as .pdf, .docx
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Download fully formatted Resume
              </p>
            </div>
            <div className="absolute bottom-[20px] left-[20px] w-full">
              <h2 className="text-[22px] font-semibold">See all Features</h2>
            </div>
          </div>

          {/* premium pricing box */}
          <div className="border relative border-black  w-full sm:w-[80%] xl:w-[30%] h-[850px] sm:h-[650px] xl:h-[800px] bg-white rounded-md p-[20px]">
            <div className="flex flex-col pb-[20px] gap-[30px] items-center">
              <div className="flex flex-col gap-1 w-full items-center text-center">
                <h2 className="text-[28px] text-clearanceDarkBlue font-semibold">
                  Premium
                </h2>
                <h3 className="text-[22px]">
                  Discover Instant AI Resume Builder
                </h3>
              </div>
              <div className="flex flex-col items-center gap-2 w-full">
                <h2 className="text-[40px] font-semibold">
                  ₹39
                  <span className="text-[17px] font-normal">
                    Premium (Cancel Any Time)
                  </span>
                </h2>
                {isButtonVisibleMonth ? (
                  <button className="px-[30px] my-2 py-[12px] text-black border-2 hover:border-clearancePink bg-clearanceLightBlue border-clearanceLightBlue hover:bg-clearancePink font-semibold rounded-md">
                    Your Plan
                  </button>
                ) : (
                  <button
                    onClick={handleMonthlyCheckout}
                    className="px-[30px] my-2 py-[12px] text-black border-2 border-clearancePink hover:bg-clearancePink font-semibold rounded-md"
                  >
                    Sign Up
                  </button>
                )}
              </div>
            </div>
            <div className="border-t-2 py-[20px] border-black flex flex-col gap-2">
              <h3 className="font-semibold text-[20px]">Everything in Free:</h3>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Create optimized resumes
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Copy and paste content from site
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Unlimited resume edits
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Save resume as .pdf, .docx
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Download fully formatted Resume
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Unlimited Resume Downloads
              </p>
              <p className="flex gap-2 items-center">
                <AiFillCheckCircle /> Save multiple variations of resume
              </p>
            </div>
            <div className="absolute bottom-[20px] left-[20px] w-full">
              <h2 className="text-[22px] font-semibold">See all Features</h2>
            </div>
          </div>
        </div>
        <div className="flex w-full justify-center gap-[30px]">
          <button
            onClick={showModalYes}
            className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white p-2"
          >
            Upgrade Plan
          </button>
          <button
            onClick={() => {
              showModalNo();
            }}
            className="w-44 mt-2 rounded-lg h-10 flex items-center justify-center bg-clearanceDarkBlue text-white text-base p-2"
          >
            Downgrade Plan
          </button>
        </div>
        {modalNo ? (
          <motion.div
            variants={modalAnim}
            initial="hidden"
            animate="visible"
            className="w-full flex flex-col items-center justify-start pt-[50px] xl:justify-center top-0 absolute h-full  rounded-md bg-[black] bg-opacity-80"
          >
            <div className="px-5 py-4 bg-white h-[300px] items-center w-[90%] sm:w-[50%] flex flex-col justify-center rounded-md">
              <h2 className="text-[20px] font-semibold text-center">
                Are You Sure, You Want to Downgrade Your Plan?
              </h2>
              <div className="flex gap-5 mt-5">
                <button
                  onClick={showModalNo}
                  className="bg-transparent px-4 py-2 border border-black rounded-md text-black font-semibold"
                >
                  No
                </button>
                <button
                  onClick={handleDowngradePlan}
                  className="bg-red-500 px-4 py-2 rounded-md text-white font-semibold"
                >
                  Yes
                </button>
              </div>
            </div>
          </motion.div>
        ) : null}
        {modalYes ? (
          <motion.div
            variants={modalAnim}
            initial="hidden"
            animate="visible"
            className="w-full flex flex-col items-center justify-start pt-[50px] xl:justify-center top-0 absolute h-full bg-[black] bg-opacity-80 rounded-md"
          >
            <div className="px-5 py-4 bg-white h-[300px] items-center w-[90%] sm:w-[50%] flex flex-col justify-center rounded-md ">
              <h2 className="text-[20px] font-semibold text-center">
                You are upgrading your Plan...
              </h2>
              <div className="flex gap-5 mt-5">
                <button
                  onClick={showModalYes}
                  className="bg-transparent px-4 py-2 border border-black rounded-md text-black font-semibold"
                >
                  No
                </button>
                <button
                  onClick={handleUpgradePlan}
                  className="bg-green-500 px-4 py-2 rounded-md text-white font-semibold"
                >
                  Yes
                </button>
              </div>
            </div>
          </motion.div>
        ) : null}
      </section>
    </>
  );
}
